import React from 'react'
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import slide1 from "../../images/incomestatement.png"
import slide2 from "../../images/screenshot-add-marks.png";
import slide3 from "../../images/reportcard.png";
import slide4 from "../../images/class_register.png";
import slide5 from "../../images/dashboard.png";

import "./slideshow.css";
  const slideProperties = {
    duration: 3000,
    pauseOnHover: true,
    arrows: false
  };
const Slideshow = () => {
  return (
    <div>
      <div className="slide-container">
        <Fade {...slideProperties}>
          <div className="each-fade">
            <div>
              <img src={slide1} />
            </div>
        
          </div>
          <div className="each-fade">
            <div>
              <img src={slide2} />
            </div>
          </div>
          <div className="each-fade">
            <div>
              <img src={slide3} />
            </div>
          </div>
          <div className="each-fade">
            <div>
              <img src={slide4} />
            </div>
          </div>
          <div className="each-fade">
            <div>
              <img src={slide5} />
            </div>
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default Slideshow