import styled from "styled-components";
export const StyledForm = styled.form`
  width: 90%;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  background: ${({ background }) => (background ? background : "transparent")};
  .label {
    font-size: 12px;
    color: blue;
    font-weight: bold;
  }
  .btnContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 1rem 0;
  }
  @media screen and (max-width: 812px) {
    width: 100%;
    padding: 15px 5px;
    background-color: red;
    height: inherit;
  }
`;
export const Input = styled.input`
  padding: 15px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 1px 3px 2px;
  background-color: white;
  border-style: none;
  outline: none;
`;
export const FormDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px;

  .selectInput {
    width: 100%;
    border-radius: 5px;
    box-shadow: 1px 3px 2px;
    background-color: white;
    border-style: none;
    outline: none;
    padding: 10px;
    margin-top: 0.5rem;
  }

  /* margin: 1rem; */
`;
export const SidedInputDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  /* margin: 0.5rem 0; */
`;
export const SideInput = styled.div`
  width: 48%;
  @media screen and (max-width: 812px) {
    width: 100%;
    margin: 0.5rem 0;
  }
`;
export const QuestionSelect = styled.div`
  /* background-color: green; */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  h2 {
    width: 100%;
    color: blue;
    text-transform: capitalize;
  }
  h5 {
    width: 100%;
  }
  .form-control {
    /* font-family: system-ui, sans-serif; */
    /* background-color: red; */
    display: flex;
    align-items: center;
    padding: 10px 0;
    p {
      margin: 0 1rem;
    }
    input[type="radio"] {
      -webkit-appearance: none;
      appearance: none;
      background-color: #fff;
      margin: 0;
      font: inherit;
      color: currentColor;
      width: 1.95em;
      height: 1.95em;
      border: 0.15em solid currentColor;
      border-radius: 50%;
      transform: translateY(-0.075em);
      display: grid;
      place-content: center;
      cursor: pointer;
    }
    input[type="radio"]::before {
      content: "";
      width: 0.99em;
      height: 0.99em;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      background-color: blue;
      box-shadow: inset 1em 1em var(--form-control-color);
    }
    input[type="radio"]:checked::before {
      transform: scale(1);
    }
  }
`;
export const SingleInput = styled.input`
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 1px 3px 2px;
  background-color: white;
  border-style: none;
  outline: none;
  margin-top: 0.5rem;
`;
export const ToggleBar = styled.div`
  display: flex;
  /* justify-content: space-around; */
  width: 45%;
  margin: 0.5rem 0;
  align-items: center;
  p {
    margin-left: 0.6rem;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  .switch input {
    position: absolute;
    width: inherit;
    height: inherit;
    background-color: red;
    top: 0rem;
    z-index: 100;
    opacity: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: pink;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  input:checked + .slider {
    background-color: orange;
    border: 1px solid #4a7fb0;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
`;
export const FormQuestion = styled.div`
  background-color: blue;
  height: 20vh;
`;
