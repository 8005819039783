import React from 'react';
import { animateScroll as scroll} from 'react-scroll'
import { 
        FaInstagram, 
        FaLinkedin, 
        FaTwitter, 
        FaMapMarkerAlt,
        FaFacebook, FaYoutube, FaPhone, FaEnvelope  } from 'react-icons/fa'
import { FooterContainer, FooterWrap, FooterLinksContainer,
        FooterLinksWrapper, 
        FooterLinkItems, 
        FooterLinkTitle, 
        FooterLink, 
        SocialMedia, 
        SocialMediaWrap, 
        SocialLogo,
        WebsiteRights, SocialIcons, SocialIconLink 
} from './FooterElements'
const Footer = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
    };
  return (
    <FooterContainer>
        <FooterWrap>
            <FooterLinksContainer>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle> Contact us </FooterLinkTitle>
                            <FooterLink to=""><FaPhone /> +234 913 3079 450</FooterLink>
                            <FooterLink to=""><FaEnvelope/> enquiries@schoolassist.ng</FooterLink>
                            <FooterLink to=""><FaMapMarkerAlt/> The CANs Park, 
                                Ibrahim Babangida Boulevard Maitama Abuja.
                            </FooterLink>
                    </FooterLinkItems>
                </FooterLinksWrapper>
            </FooterLinksContainer>
            <SocialMedia>
                <SocialMediaWrap>
                    <SocialLogo to='/' onClick={toggleHome}>schoolassist<sup>&#174;</sup></SocialLogo>
                    <WebsiteRights>
                        schoolassist &copy; {new Date().getFullYear()} All rights reserved.
                    </WebsiteRights>
                    <SocialIcons>
                        <SocialIconLink href="/" target="_blank" aria-label="Facebook">
                            <FaFacebook/>
                        </SocialIconLink>
                        <SocialIconLink href="/" target="_blank" aria-label="Instagram">
                            <FaInstagram/>
                        </SocialIconLink>
                        <SocialIconLink href="/" target="_blank" aria-label="Youtube">
                            <FaYoutube/>
                        </SocialIconLink>
                        <SocialIconLink href="//www.twitter.com/schoolassist" target="_blank" aria-label="Twitter">
                            <FaTwitter/>
                        </SocialIconLink>
                        <SocialIconLink href="/" target="_blank" aria-label="Linkedin">
                            <FaLinkedin/>
                        </SocialIconLink>
                    </SocialIcons>
                </SocialMediaWrap>
            </SocialMedia>
        </FooterWrap>
    </FooterContainer>
  )
}

export default Footer