
import './App.css';
import Home from './pages';
import SigninPage from './pages/signin';
import DemoCallbackPage from './pages/democallback';
import FeaturesPage from './pages/features';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'
function App() {
  return (
    <Router>
      {/* if you want the navbar showing up everywhere add it here */}
      {/* <Navbar/> */}
      <Routes>
        <Route path="/" element={<Home/>} />
        {/* <Route path="/features" element={<FeaturesPage/>} />
        <Route path="/signin" element={<SigninPage/>} /> */}
        <Route path="/book-demo-or-callback" element={<DemoCallbackPage/>} />
      </Routes>
    </Router>
  );
}

export default App;
