import React from 'react'
import incomeStatementSvg from '../../images/finance.svg'
import admissionSvg from '../../images/admission.svg'
import incomeExpensesCatSvg from '../../images/income_expenses_cat.svg'
import accessControlSvg from '../../images/access_control.svg'
import incomingPaymentSvg from '../../images/incoming_payments.svg'
import recordExpensesSvg from '../../images/record_expenses.svg'
import dataStorageSvg from '../../images/data_storage.svg'
import staffRecordSvg from '../../images/staff_record.svg'
import assessmentRecordSvg from '../../images/result.svg'
import Icon2 from '../../images/svg-6.svg'

import { FeaturesContainer, FeaturesH1, FeaturesWrapper, 
        FeaturesCard, FeaturesIcon, FeaturesH2, FeaturesP } from './FeaturesElements'

const Features = () => {
  return (
   <FeaturesContainer id="features">
       <FeaturesH1>Features</FeaturesH1>
       <FeaturesWrapper>
           <FeaturesCard>
               <FeaturesIcon src={admissionSvg}/>
               <FeaturesH2>Manage admissions and enrolment</FeaturesH2>
               <FeaturesP>
                   Collect student data as part of the admission process and enroll them to a class. Students can be 
                   enrolled to a new class at the start of the new school session.
               </FeaturesP>
           </FeaturesCard>
           <FeaturesCard>
               <FeaturesIcon src={Icon2}/>
               <FeaturesH2>Configure Your School Information</FeaturesH2>
               <FeaturesP>System settings option to
                    enter data such as; school name, address, phone number, email, website url 
                    and logo of your school. This information is displayed on the 
                    receipts generated by the system and on other parts of the system.
               </FeaturesP>
           </FeaturesCard>
           <FeaturesCard>
               <FeaturesIcon src={incomeStatementSvg}/>
               <FeaturesH2>Detailed Income statement generation</FeaturesH2>
               <FeaturesP>
                   Generate a detailed income statement for any given date range. Income statement contains
                    complete breakdown of income and expenses, gross income, gross expenses and calculates 
                    your net income for the selected date range.
               </FeaturesP>
           </FeaturesCard>
           <FeaturesCard>
               <FeaturesIcon src={incomeExpensesCatSvg}/>
               <FeaturesH2>Configure Income and Expenses Categories</FeaturesH2>
               <FeaturesP>Setup the income categories for your school including the amount 
                   expected for each category to eradicate errors from 
                   manual entries while collecting payments. Expenses categories are also setup to cover the 
                   various types of expenses your school pays for.
               </FeaturesP>
           </FeaturesCard>
           <FeaturesCard>
               <FeaturesIcon src={incomingPaymentSvg}/>
               <FeaturesH2>Manage Incoming Payments</FeaturesH2>
               <FeaturesP>Accept students and sundry payments. 
                   Apply discounts, scholarships and installmental payments on student payments. 
                   Issue system generated receipts for received payment. Generate report of debtors list.  </FeaturesP>
           </FeaturesCard>
           <FeaturesCard>
               <FeaturesIcon src={recordExpensesSvg}/>
               <FeaturesH2>Record Expenses</FeaturesH2>
               <FeaturesP>Store your school's expenses according to configure expenses categories. 
                   Filter the list of recorded expenses by expenses categories 
                   based on a selected date range. </FeaturesP>
           </FeaturesCard>
           <FeaturesCard>
               <FeaturesIcon src={assessmentRecordSvg}/>
               <FeaturesH2>Assessment Record Management</FeaturesH2>
               <FeaturesP>Manage your student assesment result. Generate result master sheet for each class. 
                Generate student report card. Configure subjects taught for different school levels. 
               </FeaturesP>
           </FeaturesCard>
           <FeaturesCard>
               <FeaturesIcon src={dataStorageSvg}/>
               <FeaturesH2>Student Record Management</FeaturesH2>
               <FeaturesP>Manage your student personal record, medical records and class record.</FeaturesP>
           </FeaturesCard>
           <FeaturesCard>
               <FeaturesIcon src={staffRecordSvg}/>
               <FeaturesH2>Staff Record Management</FeaturesH2>
               <FeaturesP>Manage your staff personal record, qualifications, departments they belong, subjects they teach 
                   and classes they teach.
               </FeaturesP>
           </FeaturesCard>
           <FeaturesCard>
               <FeaturesIcon src={accessControlSvg}/>
               <FeaturesH2>Secure Data access control </FeaturesH2>
               <FeaturesP> Give your users role based permissions to various parts of the system.
                   System security ensures that protected resources are not accessible to unauthorised
                   users.
               </FeaturesP>
           </FeaturesCard>
          
       </FeaturesWrapper>
   </FeaturesContainer>
  )
}

export default Features