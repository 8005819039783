import React from 'react'
import { Container,  
    FormWrap,
    Icon,
    FormContent, 
     } from './DemoCallbackElements';
import Logo from '../../images/logo-01.png';

const DemoCallback = () => {
  return (
   <>
      <Container>
        <FormWrap>
            <Icon to="/"><img style={{'width':'200px'}}src={Logo}/> </Icon>
            <FormContent>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfBcbqSmuzwgEGkxraMTkypIFkaSAjpvWZIG0YpfU_dNj7UEg/viewform?embedded=true" width="640" height="2103" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </FormContent>
        </FormWrap>
    </Container>
   </>
  )
}

export default DemoCallback