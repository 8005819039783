import svg3 from '../../images/svg-3.svg';
import benefitSvg from '../../images/benefits.svg'
import programmerSvg from '../../images/programmer.svg'

// export const homeObjOne = {
//     id: 'features',
//     lightBg: true,
//     lightText: false,
//     lightTextDesc: false,
//     topLine: 'Features',
//     headline: 'Unlimited Transactions with zero fees',
//     description: 'Get access to our exclusive app that allows you to send unlimited transactions without getting charged any fees',
//     buttonLabel: 'Learn More',
//     imgStart: true,
//     img: featuresSvg,
//     alt: 'Features',
//     dark: false,
//     primary: false,
//     darkText: true
// };

export const homeObjTwo = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'About Us',
    headline: '',
    description: `<p> SchoolAssist® is a school management software solution which aids in automating
    the school's daily operations efficiently and provide insightful data-driven reports in order for
    stakeholders to make better and faster decisions that improve the productivity of their school. </p>
     
     <p>SchoolAssist® automates the school's operations by providing a cloud based MIS solution 
      where the school's student, financial and staff records are securely stored and 
      easily accessible from any device and location. </p>
      <p>
      The Finance Management module improves 
      the consistency and accuracy of the school's financial records by providing a user-friendly way to configure income and expense categories,
      accept student and sundry payments, generate income statement and record expenses.
      From Admissions & Enrollment to Managing Student and Staff records, SchoolAssist® handles every process
      smoothly and efficiently.
      </p>
      <p> SchoolAssist is suitable for Nursery, Primary and Secondary schools. The software is a registered trademark of Tafitech Ltd (RC 1747608) software company based in Abuja, Nigera. 
    With over 25 years combined experience in education and technology across our team, we are rightly positoned 
    to continuously deliver effective school management solutions using SchoolAssist®.</p>`,
    buttonLabel: 'Request a demo today',
    imgStart: true,
    img: programmerSvg,
    alt: 'Car',
    dark: false,
    primary: false,
    darkText: true,
    buttonPresent: true,
};



export const homeObjThree = {
    id: 'benefits',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Benefits of schoolassist',
    headline: '',
    description: 
    `
    <ul>
    <li>Web-based solution, with 99.9% up-time,
    providing constant access to school information from any web-enabled device
    </li>
    <li>Data is stored securely in the cloud so you don’t have to worry about physical damages or loss of paper records.</li>
    <li> Maintain consistency and accuracy of school financial records including income and expenses.
    <li> Improved efficiency in school admission and enrollment process </li>
    <li> Generate income statement containing daily income and expenses </li>
    <li> User-friendly interface making data easy to interpret and act upon. </li>
    <li> All Payment receipts can be easily retrieved or accessed at anytime from any location with the click of a button.</li>
    <li> Efficient management of school’s financial records, student records and staff records.</li>
    <li> Expert help and advice from our education data and technology specialists. </li>
    </ul>
    ` 
   ,
    buttonLabel: 'Request a demo today',
    imgStart: false,
    img: benefitSvg,
    alt: 'Car',
    dark: false,
    primary: false,
    darkText: true,
    buttonPresent: true,
};

export const featureOne = {
    id: 'featureOne',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Premium Bank',
    headline: 'Unlimited Transactions with zero fees',
    description: 'Get access to our exclusive app that allows you to send unlimited transactions without getting charged any fees',
    buttonLabel: 'Request a demo today',
    imgStart: true,
    img: svg3,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: true,
    buttonPresent: false,
};
