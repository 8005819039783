import React, { useState } from "react";
import { LinkButton } from "../ButtonElement";
import {
  HeroContainer,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
 
} from "./HeroElements";
import Slideshow from "./Slideshow";


const HeroSection = ({ primary, dark }) => {
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };
  return (
    <HeroContainer id="home">
      <HeroContent>
        <HeroH1>
          {" "}
          schoolassist<sup>&#174;</sup>{" "}
        </HeroH1>
        <HeroP>
          <strong>
            Keep track of your school's income and expenses, Generate
            detailed income statement report, Make better financial decisions
            for your school.
          </strong>
        </HeroP>
        <HeroP>
          SchoolAssist is an online school management software that simplifies
          your school’s academic and administrative processes effortlessly by
          providing an efficient means to manage your school’s admission
          process, financial records, students assessments records and staff
          records.
        </HeroP>
        <HeroBtnWrapper>
         <LinkButton to="/book-demo-or-callback">Request a demo today</LinkButton>
        </HeroBtnWrapper>
      </HeroContent>
      <div className="slideWrapper">
        <Slideshow/>
      </div>
      
    </HeroContainer>
  );
};

export default HeroSection;
