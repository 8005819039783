import styled from "styled-components";
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";

export const HeroContainer = styled.div`
  background-color: #ecf0f1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 120px 64px;
  height: inherit;
  /* position: relative; */
  /* z-index: 1; */
  @media screen and (max-width: 980px) {
    flex-direction: column;
    height: inherit;
    padding: 10px;
  }
  @media screen and (max-width: 700px) {
    flex-direction: column;
    height: inherit;
    padding: 10px;
  }

  /* :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.6) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    z-index: 2;
  } */
`;

export const HeroBg = styled.div`
  /* position: absolute; */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: pink;
`;

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`;

export const HeroContent = styled.div`
  z-index: 3;
  /* max-width: 1200px; */
  /* position: absolute; */
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 40%;
  @media screen and (max-width: 980px) {
    width: 100%;
    margin: 4rem 0;
    margin-top: 8rem;
  }
  @media screen and (max-width: 700px) {
    width: 100%;
    /* margin-top: 8rem; */
    /* background-color: red; */
  }
`;

export const HeroH1 = styled.h1`
  /* color: #fff; */
  color: #172b4d;
  font-size: 48px;
  /* text-align: left; */

  @media screen and (max-width: 700px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const HeroP = styled.p`
  margin-top: 24px;
  /* color: #fff; */
  color: #172b4d;
  font-size: 16px;
  /* text-align: center; */
  max-width: 600px;

  @media screen and (max-width: 700px) {
    font-size: 16px;
  }

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;
export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  /* display: flex; */
  flex-direction: column;
  /* align-items: center; */
`;
export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;

export const HeroBGImage = styled.div`
  /*background-color: url(${(props) => props.imgUrl});*/
  background-color: #ccc;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 57%;
  height: 445px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  h1 {
    color: blue;
  }
  div {
    position: absolute;
    width: 77%;
    top: 0.8rem;
    left: 4rem;
    visibility: hidden;
    border-radius: 5px;
    animation: 12s autoplay1 infinite;
    /* display: none; */
    /* @media screen and (max-width: 1510px) {
    } */
    /* @media screen and (max-width: 1460px) {
      width: 73%;
      left: 5.3rem;
    } */
    @media screen and (max-width: 1400px) {
      width: 77%;
      left: 4rem;
    }
    @media screen and (max-width: 1320px) {
      width: 82%;
      left: 3rem;
    }
    @media screen and (max-width: 1285px) {
      width: 85%;
    }
    @media screen and (max-width: 1253px) {
      width: 90%;
      left: 1.8rem;
    }
    @media screen and (max-width: 1193px) {
      width: 71%;
      left: 5rem;
      top: 1rem;
    }
    @media screen and (max-width: 1141px) {
      width: 75%;
      left: 4rem;
    }
    @media screen and (max-width: 1110px) {
      width: 78%;
      left: 3.7rem;
    }
    @media screen and (max-width: 1080px) {
      width: 80%;
      left: 3rem;
    }
    @media screen and (max-width: 1050px) {
      width: 70%;
      left: 4.5rem;
    }
    @media screen and (max-width: 1035px) {
      width: 72%;
      left: 4.3rem;
    }
    @media screen and (max-width: 1000px) {
      width: 74%;
      left: 3.8rem;
    }
    @media screen and (max-width: 979px) {
      width: 72%;
      left: 7.2rem;
      top: 1.5rem;
    }
    @media screen and (max-width: 960px) {
      width: 75%;
      left: 4.5rem;
    }
    @media screen and (max-width: 905px) {
      width: 75%;
      left: 5rem;
    }
    @media screen and (max-width: 863px) {
      width: 70%;
      left: 6.5rem;
    }
    @media screen and (max-width: 830px) {
      width: 76%;
      left: 4.3rem;
      top: 0.5rem;
    }
    @media screen and (max-width: 770px) {
      width: 80%;
      left: 3rem;
    }
    @media screen and (max-width: 750px) {
      width: 83%;
      left: 3rem;
    }
    @media screen and (max-width: 700px) {
      width: 80%;
      /* left: 4rem; */
      top: 1rem;
    }
    @media screen and (max-width: 630px) {
      width: 70%;
      top: 0.6rem;
      left: 4.5rem;
    }
    @media screen and (max-width: 600px) {
      width: 73%;
      left: 4rem;
    }
    @media screen and (max-width: 590px) {
      width: 75%;
      left: 3.6rem;
    }
    @media screen and (max-width: 565px) {
      width: 80%;
      left: 1.8rem;
    }
    @media screen and (max-width: 530px) {
      width: 70%;
      left: 4.5rem;
    }
    @media screen and (max-width: 490px) {
      width: 75%;
      left: 3.7rem;
    }
    @media screen and (max-width: 459px) {
      width: 80%;
      left: 2.9rem;
    }
    @media screen and (max-width: 445px) {
      width: 83%;
      left: 2.3rem;
    }
    @media screen and (max-width: 430px) {
      width: 79%;
      left: 1.9rem;
      top: 0.5rem;
    }
    @media screen and (max-width: 400px) {
      width: 83%;
      left: 1rem;
      top: 0.35rem;
    }
    @media screen and (max-width: 380px) {
      width: 76%;
      left: 2rem;
      top: 0.35rem;
    }
    @media screen and (max-width: 325px) {
      width: 85%;
      left: 1.5rem;
    }
    @media screen and (max-width: 310px) {
    }
    @media screen and (max-width: 290px) {
      width: 79.8%;
      left: 1.2rem;
      top: 0.4rem;
    }
  }
  @keyframes autoplay1 {
    0% {
      visibility: visible;
    }
    33.33% {
      visibility: hidden;
    }
  }
  > *:nth-child(1) {
    animation-delay: 0s;
  }
  > *:nth-child(2) {
    animation-delay: 4s;
  }
  > *:nth-child(3) {
    animation-delay: 8s;
  }
  @media screen and (max-width: 1510px) {
    width: 90%;
    height: 400px;
  }
  @media screen and (max-width: 1460px) {
    width: 90%;
    height: 350px;
  }

  @media screen and (max-width: 1193px) {
    height: 300px;
    width: 100%;
  }
  @media screen and (max-width: 1050px) {
    width: 100%;
    height: 250px;
  }
  @media screen and (max-width: 980px) {
    height: 450px;
    width: 90%;
    display: flex;
    margin: auto;
  }
  @media screen and (max-width: 864px) {
    height: 400px;
    width: 90%;
  }
  @media screen and (max-width: 770px) {
  }
  @media screen and (max-width: 700px) {
    height: 300px;
    width: 80%;
    margin: auto;
  }
  @media screen and (max-width: 630px) {
    width: 80%;
    height: 250px;
  }
  @media screen and (max-width: 530px) {
    width: 100%;
    height: 255px;
  }
  @media screen and (max-width: 430px) {
    width: 90%;
    height: 210px;
  }
  @media screen and (max-width: 380px) {
    width: 90%;
    height: 180px;
  }
  @media screen and (max-width: 365px) {
    width: 100%;
    height: 190px;
  }
  @media screen and (max-width: 290px) {
    height: 150px;
    width: 100%;
  }
`;
export const DesktopImg = styled.img`
  width: 100%;
  height: 390px;
  @media screen and (max-width: 1510px) {
    height: 290px;
  }
  @media screen and (max-width: 1460px) {
    height: 290px;
  }

  @media screen and (max-width: 1193px) {
    height: 250px;
  }
  @media screen and (max-width: 1050px) {
    height: 210px;
  }
  @media screen and (max-width: 979px) {
    height: 380px;
  }
  @media screen and (max-width: 864px) {
    height: 330px;
  }
  @media screen and (max-width: 830px) {
    height: 330px;
  }
  @media screen and (max-width: 770px) {
    height: 343px;
  }
  @media screen and (max-width: 700px) {
    height: 253px;
  }
  @media screen and (max-width: 630px) {
    height: 217px;
  }
  @media screen and (max-width: 530px) {
    height: 225px;
  }
  @media screen and (max-width: 430px) {
    height: 180px;
  }
  @media screen and (max-width: 380px) {
    height: 164px;
  }
  @media screen and (max-width: 365px) {
    height: 160px;
  }
  @media screen and (max-width: 290px) {
    height: 129px;
  }
  /* top: 3.3rem; */
  /* position: absolute; */
`;
