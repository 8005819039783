import React from 'react'
import ScrollToTop from '../components/ScrollToTop'
import DemoCallback from '../components/DemoCallback'

const DemoCallbackPage = () => {
  return (
    <>
    <ScrollToTop/>
    <DemoCallback />
    </>
  )
}

export default DemoCallbackPage