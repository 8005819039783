import React from 'react'
import Icon3 from '../../images/svg-7.svg'
import trainingSvg from '../../images/training.svg'
import techSupportSvg from '../../images/techsupport.svg'
import reportServiceSvg from '../../images/reportservice.svg'
import dataMigrationSvg from '../../images/data_migration.svg'
import { ServicesContainer, ServicesH1, ServicesWrapper, 
        ServicesCard, ServicesIcon, ServicesH2, ServicesP } from './ServicesElements'

const Services = () => {
  return (
   <ServicesContainer id="services">
       <ServicesH1>Our Services</ServicesH1>
       <ServicesWrapper>
           <ServicesCard>
               <ServicesIcon src={techSupportSvg}/>
               <ServicesH2>Technical Support</ServicesH2>
               <ServicesP> We provide support, assistance and expert knowledge to guide you through adopting schoolassist.</ServicesP>
           </ServicesCard>
           <ServicesCard>
               <ServicesIcon src={reportServiceSvg}/>
               <ServicesH2>Customised Reports </ServicesH2>
               <ServicesP>We deliver customised reports from your data and records. </ServicesP>
           </ServicesCard>
           <ServicesCard>
               <ServicesIcon src={Icon3}/>
               <ServicesH2>Implementation</ServicesH2>
               <ServicesP>We ensure your implementation is closely supported by a dedicated Project Manager.</ServicesP>
           </ServicesCard>
           <ServicesCard>
               <ServicesIcon src={trainingSvg}/>
               <ServicesH2>Staff Training </ServicesH2>
               <ServicesP>We provide onsite and online training to your staff on the use of schoolassist.</ServicesP>
           </ServicesCard>
           <ServicesCard>
               <ServicesIcon src={dataMigrationSvg}/>
               <ServicesH2>Data Migration</ServicesH2>
               <ServicesP>We provide data migration services from offline records (paper-based) or existing MIS.</ServicesP>
           </ServicesCard>
       </ServicesWrapper>
   </ServicesContainer>
  )
}

export default Services