import styled from "styled-components";
export const ModalOverlay = styled.div`
  width: 100%;
  display: ${({ show }) => (show ? "flex" : "none")};
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.692);
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 100;
`;
export const ModalContainer = styled.div`
  height: fit-content;
  width: 50%;
  background-color: #ecf0f1;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  position: absolute;
  padding: 10px 0;
  z-index: 100;
  @media screen and (max-width: 1050px) {
    width: 75%;
  }
  @media screen and (max-width: 815px) {
    width: 70%;
  }
  @media screen and (max-width: 500px) {
    height: 45vh;
    width: 90%;
    padding: 0;
    height: fit-content;
    /* top: 1rem; */
    /* position: none; */
  }
  .cancelBtn {
    /* background-color: #5864f6; */
    position: absolute;
    color: #5864f6;
    right: 0.5rem;
    top: 0.5rem;
    bottom: 5;
    border-radius: 5px;
    font-size: 30px;
    box-shadow: 2px 3px 3px;
    @media screen and (max-width: 768px) {
      font-size: 20px;
      right: 1rem;
      top: 8rem;
      z-index: 204;
    }
  }
`;
