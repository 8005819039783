import React from 'react'
import { SidebarContainer,Icon, CloseIcon, SidebarWrapper, SidebarMenu,
SidebarLink, SidebarLinkToPage } from './SidebarElements'
const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to="benefits" onClick={toggle}>Benefits</SidebarLink>
                <SidebarLink to="features" onClick={toggle}>Features</SidebarLink>
                <SidebarLink to="about" onClick={toggle}>About Us</SidebarLink>   
                <SidebarLink to="services" onClick={toggle}>Services</SidebarLink> 
            </SidebarMenu>
            {/* <SideBtnWrap>
                <SidebarRoute to="/signin">Sign In</SidebarRoute>
            </SideBtnWrap> */}
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar